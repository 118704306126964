import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import useSWR from "swr"
import { BENEFITS } from "helpers/url_helper"
import { fetcher } from "utils/API/SWRFetcher"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
import { claimPaths } from "common/data/routePaths"
const FOBsDropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  // fetch the permissions of staff taks module
  const url = `${BENEFITS}?class_allowed=1&has_slug=1&is_active=1`

  const { isLoading, error, data } = useSWR(
    () => (url && isOpen ? [url] : null),
    ([url]) => fetcher(url),
    {
      revalidateOnFocus: false,
    }
  )
  return (
    <Dropdown
      className="d-none d-lg-inline-block"
      isOpen={isOpen}
      title="FOBs"
      data-toggle="tooltip"
      toggle={() => {
        setIsOpen(!isOpen)
      }}
    >
      {/* Dropw Menu Toggler */}

      <DropdownToggle className="btn header-item noti-icon" tag="button">
        <i className="bx bx-plus" />
      </DropdownToggle>

      <DropdownMenu className="dropdown-menu-end">
        {isLoading && !data && <MultiSkeleton length={5} />}
        {data && data?.length > 0 && (
          <Fragment>
            {/* static all  */}
            <Link
              className="dropdown-item"
              onClick={() => {
                setIsOpen(false)
              }}
              to={claimPaths.claimAssignment}
            >
              All
            </Link>

            {data?.map(fob => (
              <Link
                className="dropdown-item"
                key={fob.id}
                onClick={() => {
                  setIsOpen(false)
                }}
                to={{
                  pathname: `${claimPaths.claimAssignment}`,
                  state: { benefit_id: fob?.id, benefit_name: fob?.name },
                }}
              >
                {fob.name}
              </Link>
            ))}
          </Fragment>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default FOBsDropdownMenu
