import PropTypes from "prop-types"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Modal, Row } from "reactstrap"

// i18n
import { withTranslation } from "react-i18next"
import { TableContainer } from "components/Common"
import { getAllClaims } from "helpers/Claims/ClaimForm"
import {
  NotificationMessage,
  apiErrorrHandler,
  fieldFinder,
  generateColumns,
} from "utils"
import { useSelector } from "react-redux"
import {
  claimAdmissionDetailsId,
  claimDetailsModuleId,
  claimFormModuleId,
} from "utils/modulesIds"
import {
  buttonsTitles,
  hideProcessingPermissionStatuses,
  modalHeaders,
} from "common/data"
import { SingleRowAction } from "../Common/Tables"
import { claimPaths } from "common/data/routePaths"
import {
  getAllActions,
  getAllSubModules,
} from "helpers/Permissions/permissions_helper"
import { useModuleActions } from "hooks"
import AbnormalMedicalHistory from "../Beneficiary/AbnormalMedicalHistory"
import { isEmpty, omitBy, stubTrue } from "lodash"
import { AsyncSearchSelect } from "../Common/Forms"
import * as URLs from "helpers/url_helper"
import { getAllHealthCareProviders } from "helpers/Stakeholders/healthCare"
import { getAllBenefits } from "helpers/CommonData/benefits"
import { getAllPredefineds } from "helpers/Settings/predefineds/predefineds_herlper"
import CustomButton from "../Common/General/CustomButton"
import { getAllMedicalCodes } from "helpers/CommonData/medicalCodes"
import MultiSkeleton from "../Common/MultiSkeleton"
import ClaimNotesModal from "./ClaimNotesModal"
import ItemServicesModal from "../Beneficiary/ItemServicesModal"

// this component could be imported in claim
// of beneficiary
// in beneficiary we need to fetch claim permission
// in claim we will have it already so no need to fetch it again
const MedicalFilesTableModal = ({
  show,
  onCloseClick,
  beneficiaryId,
  beneficiary,
  notInClaim,
  t,
}) => {
  const [claims, setClaims] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [columns, setColumns] = useState([])
  const [admissionFields, setAdmissionFields] = useState([])
  const [actionFields, setActionFields] = useState([])

  // to store the claim id for notes modal
  const [claimToNote, setClaimToNotes] = useState(null)

  // filters
  const [advancedFilters, setAdvancedFilters] = useState({})

  const { isViewProcessingPermission } = useModuleActions()

  const { claimFormFields, moduleId, claimTableFields } = useSelector(
    state => ({
      loading: state?.Module?.loading,
      claimTableFields: state?.Module?.actions?.fields,
      moduleId: state?.Module?.actions?.id,
      claimFormFields: state?.Module?.subModules
        ?.find(item => item.id === claimDetailsModuleId)
        ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)
        ?.fields,
    })
  )

  useEffect(() => {
    if (actionFields?.length > 0) {
      const generatedColumns = generateColumns([
        ...actionFields,
        {
          label_name: "Type",
          index_name: "type.name",
          name: "type.name",
          display_order: 2,
        },
        {
          label_name: "Insurer Amt $",
          index_name: "payer_total_amount",
          name: "payer_total_amount",
        },
        {
          label_name: "BC $",
          index_name: "business_consideration_amount",
          name: "business_consideration_amount",
          display_order: 5,
        },
        {
          label_name: "Discharge Date",
          index_name: "discharge_date",
          name: "discharge_date",
          field_type: "date",
          display_order: 3.1,
        },

        { ...fieldFinder(admissionFields, 712), display_order: 4.1 },
        {
          label_name: "Discharge Diagnoses",
          index_name: "medical_codes.name",
          name: "medical_codes.name",
          field_type: "array",
          display_order: 4.2,
          disableSortBy: true,
        },
        fieldFinder(admissionFields, 407),
        fieldFinder(admissionFields, 415),
        fieldFinder(admissionFields, 401),
      ])

      setColumns([
        ...generatedColumns.filter(
          item =>
            item.accessor !== "beneficiary.name" &&
            item.accessor !== "beneficiary.code" &&
            item.accessor !== "beneficiary.policy.payer.abbreviation" &&
            item.accessor !== "claim_assigned_user.user.name" &&
            item.accessor !== "beneficiary.policy.name" &&
            item.accessor !== "estimation_amount" &&
            item.accessor !== "total_claimed_amount" &&
            item.accessor !== "total_approved_amount" &&
            item.accessor !== "cause.name" &&
            item.accessor !== "internal_notes" &&
            item.accessor !== "authorization_notes"
        ),
        {
          Header: buttonsTitles.actions,
          accessor: "actions",
          disableFilters: true,
          Cell: cellProps => {
            // Actions Icons in each row with its props
            const row = cellProps.row.original
            return (
              <div className="d-flex gap-2">
                <SingleRowAction
                  actionName="view"
                  target="_blank"
                  to={`${claimPaths.claimForm}/view/${row?.id}`}
                  iconClass="mdi mdi-eye"
                  record={row}
                />
                <SingleRowAction
                  actionName="Notes"
                  onClick={() => {
                    setClaimToNotes(row?.id)
                  }}
                  iconClass="mdi mdi-alpha-n-box-outline"
                  record={row}
                />
                {/* Processing icon for claim */}
                {/* It will be displayed only if claim.status.id === 267 (received Claim Only) */}
                {isViewProcessingPermission &&
                  !hideProcessingPermissionStatuses.includes(
                    row?.status?.id
                  ) && (
                    <SingleRowAction
                      target="_blank"
                      to={`${claimPaths.claimForm}/${claimPaths.viewProcessing}/${row?.id}`}
                      iconClass="mdi mdi-calculator"
                      actionName={isViewProcessingPermission?.name}
                      record={row}
                    />
                  )}
              </div>
            )
          },
        },
      ])
    }
  }, [actionFields])

  const getClaims = async ({
    page,
    per_page,
    sort_by,
    sort_type,
    search_value,
    search_keys,
    search_values,
    // status_id,
    // type_id,
  }) => {
    try {
      setIsLoading(true)

      // const searchKeys = Object.keys(omitBy(advancedFilters, isEmpty)).join(",")
      // const searchValues = Object.values(omitBy(advancedFilters, isEmpty)).join(
      //   ","
      // )
      const res = await getAllClaims({
        page,
        per_page,
        sort_by,
        sort_type,
        search_value,
        search_keys,
        search_values,
        beneficiary_id: beneficiaryId,
        // status_id,
        // type_id: pageURL === claimPaths.claimForm.replace("/", "") ? 233 : 234,
      })
      const resClaims = res.items
      setClaims(
        resClaims.map(item => ({
          ...item,
          medical_codes: item.medical_codes?.filter(item => item.discharge),
        }))
      )
      // setClaimIds(res.items.map(item => item.id))
      setPagination({
        size: res.size,
        page: res.page,
        per_page: res.per_page,
        total_pages: res.total_pages,
        total: res.total_size,
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("Error", errorMessage)
    }
  }

  const getClaimPermission = async () => {
    setLoading(true)
    try {
      const level2Res = await getAllActions(claimFormModuleId)
      const level3Res = await getAllSubModules(claimFormModuleId)
      setActionFields(level2Res.item.fields)
      const admissionModulefields = level3Res.item.sub_modules
        ?.find(item => item.id === claimDetailsModuleId)
        ?.sub_modules?.find(item => item.id === claimAdmissionDetailsId)?.fields
      setAdmissionFields(admissionModulefields)
    } catch (error) {
      const errorMessage = apiErrorrHandler(error)
      NotificationMessage("error", errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // fetch claim permission if the component isn’t in the claim form

  useEffect(() => {
    if (notInClaim) {
      getClaimPermission()
    } else {
      setActionFields(claimTableFields)
      setAdmissionFields(claimFormFields)
    }
  }, [notInClaim])

  return (
    <Fragment>
      <Modal
        size="xl"
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        className="modal-fullscreen"
      >
        <div className="modal-header">
          {/* Modal Header Title */}
          <h5 className="modal-title mt-0" id="myModalLabel">
            {`${modalHeaders.medicalFile}: ${beneficiary?.name}`}
          </h5>

          {/* close button on the top right section */}
          <button
            aria-label="Close Modal"
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          {/* abnoraml history it will be shown in beneficiary only not in claim */}
          <div className="d-flex align-items-center gap-2">
            {notInClaim && (
              <AbnormalMedicalHistory beneficiaryId={beneficiaryId} />
            )}
            <ItemServicesModal
              beneficiaryId={beneficiaryId}
              actionFields={actionFields}
              admissionFields={admissionFields}
            />
          </div>
          {/* filters */}
          {loading ? (
            <MultiSkeleton length={1} />
          ) : (
            <Row className="align-items-center gap-0">
              <Col md={2}>
                <AsyncSearchSelect
                  id="400"
                  name="healthcare_provider_id"
                  inputField={fieldFinder(actionFields, 400)}
                  placeholder="Search By Name"
                  value={
                    advancedFilters[fieldFinder(actionFields, 400)?.name] || ""
                  }
                  customOnChange={(selectedValue, { actions }) => {
                    setAdvancedFilters(prev => {
                      return {
                        ...prev,
                        [fieldFinder(actionFields, 400)?.name]: selectedValue
                          ? selectedValue?.first_name
                          : "",
                      }
                    })
                  }}
                  url={URLs.HEALTHCAREPROVIDER}
                  getOptionsData={getAllHealthCareProviders}
                  disableFetch={true}
                  clearable={true}
                  onBlur={() => {}}
                  searchKey="first_name"
                  mappingLabel="first_name"
                  mappingValue="id"
                  wrapperClassNames="mb-3"
                />
              </Col>
              <Col md={2}>
                <AsyncSearchSelect
                  id="386"
                  name="benefit_id"
                  inputField={fieldFinder(actionFields, 386)}
                  placeholder="Search By Name"
                  value={
                    advancedFilters[fieldFinder(actionFields, 386)?.name] || ""
                  }
                  customOnChange={(selectedValue, { actions }) => {
                    setAdvancedFilters(prev => {
                      return {
                        ...prev,
                        [fieldFinder(actionFields, 386)?.name]: selectedValue
                          ? selectedValue?.name
                          : "",
                      }
                    })
                  }}
                  url={URLs.BENEFITS}
                  getOptionsData={getAllBenefits}
                  urlAddOns={{
                    is_active: 1,
                    class_allowed: 1,
                    has_slug: 1,
                  }}
                  disableFetch={true}
                  clearable={true}
                  onBlur={() => {}}
                  searchKey="name"
                  mappingLabel="name"
                  mappingValue="id"
                  wrapperClassNames="mb-3"
                />
              </Col>
              <Col md={2}>
                <AsyncSearchSelect
                  id="708"
                  name="status_id"
                  inputField={fieldFinder(actionFields, 708)}
                  placeholder="Search By Name"
                  value={
                    advancedFilters[fieldFinder(actionFields, 708)?.name] || ""
                  }
                  customOnChange={selectedValue => {
                    setAdvancedFilters(prev => {
                      return {
                        ...prev,
                        [fieldFinder(actionFields, 708)?.name]: selectedValue
                          ? selectedValue?.name
                          : "",
                      }
                    })
                  }}
                  url={`${URLs.PREDEFINEDS}?parent_slug=${
                    fieldFinder(actionFields, 708)?.predefined_value?.slug
                  }`}
                  getOptionsData={getAllPredefineds}
                  urlAddOns={{
                    parent_slug: fieldFinder(actionFields, 708)
                      ?.predefined_value?.slug,
                  }}
                  disableFetch={true}
                  clearable={true}
                  onBlur={() => {}}
                  searchKey="name"
                  mappingLabel="name"
                  mappingValue="id"
                  wrapperClassNames="mb-3"
                />
              </Col>
              <Col md={2}>
                <AsyncSearchSelect
                  id="712"
                  name="general_assessment_id"
                  inputField={{
                    ...fieldFinder(admissionFields, 712),
                    is_required: 0,
                  }}
                  value={advancedFilters["general_assessment_id"] || ""}
                  customOnChange={selectedValue => {
                    setAdvancedFilters(prev => {
                      return {
                        ...prev,
                        ["general_assessment_id"]: selectedValue
                          ? `${selectedValue?.id}`
                          : "",
                      }
                    })
                  }}
                  urlAddOns={{
                    medical_code_type_id: 2,
                    parent_id: "",
                  }}
                  url={URLs.MEDICALCODES}
                  getOptionsData={getAllMedicalCodes}
                  disableFetch={true}
                  clearable={true}
                  onBlur={() => {}}
                  searchKey="name"
                  mappingLabel="name"
                  mappingValue="id"
                  wrapperClassNames="mb-3"
                />
              </Col>

              <Col md={1}>
                <div className="d-flex align-items-center gap-2">
                  <CustomButton
                    className="btn btn-secondary w-sm text-center mt-2"
                    onClick={() => {
                      getClaims({
                        page: 1,
                        per_page: 10,
                        search_keys: Object.keys(
                          omitBy(advancedFilters, isEmpty)
                        ).join(","),
                        search_values: Object.values(
                          omitBy(advancedFilters, isEmpty)
                        ).join(","),
                      })
                    }}
                    disabled={
                      Object.values(omitBy(advancedFilters, isEmpty)).length ===
                        0 || isLoading
                        ? true
                        : false
                    }
                    title={buttonsTitles.filter}
                  />
                  <CustomButton
                    className="btn btn-gray-600 w-sm text-center mt-2"
                    disabled={
                      Object.values(omitBy(advancedFilters, isEmpty)).length ===
                        0 || isLoading
                        ? true
                        : false
                    }
                    onClick={() => {
                      setAdvancedFilters({})
                      getClaims({
                        page: 1,
                        per_page: 10,
                      })
                    }}
                    title={buttonsTitles.clear}
                  />
                </div>
              </Col>
            </Row>
          )}

          <TableContainer
            removeActionsColumns={true}
            // removeActionsButtons={true}
            columns={columns}
            data={claims}
            handleAdd={null}
            pagination={pagination}
            queryAddOn={{
              beneficiary_id: beneficiaryId,
              search_keys: Object.keys(omitBy(advancedFilters, isEmpty)).join(
                ","
              ),
              search_values: Object.values(
                omitBy(advancedFilters, isEmpty)
              ).join(","),
            }}
            getData={getClaims}
            loading={isLoading}
            basicTable={true}
            sheetName="Claim Medical Files"
          />
        </div>

        <div className="modal-footer">
          <button
            aria-label="Close Modal"
            type="button"
            className="btn btn-secondary"
            onClick={onCloseClick}
          >
            {buttonsTitles.close}
          </button>
        </div>
      </Modal>

      {claimToNote && (
        <ClaimNotesModal
          show={claimToNote ? true : false}
          onCloseClick={() => setClaimToNotes(null)}
          claimId={claimToNote}
          notInClaim={notInClaim}
        />
      )}
    </Fragment>
  )
}

MedicalFilesTableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  isSaving: PropTypes.bool,
  children: PropTypes.any,
  beneficiaryId: PropTypes.number,
  // modalTitle: PropTypes.string,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default withTranslation()(MedicalFilesTableModal)
