import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import ExportTable from "./ExportTable"
import ShowHideColumns from "./ShowHideColumns"

//i18n
import { withTranslation } from "react-i18next"

// custom hook

import useModuleActions from "hooks/useModuleActions"
import {
  claimBatchModuleId,
  claimFormModuleId,
  productPCIId,
} from "utils/modulesIds"

import { AysncExportCSVModal } from "components/TP/Common/Modals"
import { buttonsTitles, claimsTypes, staticLabels } from "common/data"
import { useSelector } from "react-redux"
import { claimPaths } from "common/data/routePaths"
import { useLocation } from "react-router-dom"

const TableActionsButtons = ({
  t,
  handleAdd,
  addPageLink,
  isAddInNewTab,
  batchPageLink,
  showOptions,
  setShowOptions,
  columns,
  fullColumns,
  data,
  sheetName,
  sheetHeader,
  getSheetData,
  queryAddOn,
  customCSVHeader,
  customCSVData,
  customPDFHeader,
  customPDFData,
  subModuleId,
  moduleId,
  addDropDown,
  isBackPermission,
  backPageLink,
  isUnusedMarkPermission,
  handleAddMarkUnused,
  addOnButtons,
  inCludeExportButton,
  asyncExport,
  noPagingation,
  generateCustomData,
  claimAdvancedFilters,
  onClaimOwnerChange,
  addOnButtonsComponent,
  handleForceLogout,
  isImportInSameTab,
  includeReloadButton,
  onReloadClick,
  // ref,
}) => {
  const {
    isAddPermission,
    isTableViewPermission,
    isTableExportPermission,
    isTableFilterPermission,
    isBatchUploadPermission,
    isAddClaimReimbursementPermission,
    isUserForceLogout,
  } = useModuleActions(subModuleId && subModuleId)

  const [isModal, setIsModal] = useState(false)

  const { moduleId: stateModuleId } = useSelector(state => ({
    moduleId: state?.Module?.id,
  }))

  const location = useLocation()
  const pathname = location.pathname
  return (
    <Fragment>
      <div className="d-flex justify-content-md-end flex-wrap justify-content-start gap-2">
        {addOnButtonsComponent ? addOnButtonsComponent : null}

        {/* Show Own Authorization  */}
        {/* it will be shown in claim only */}
        {stateModuleId &&
        stateModuleId === claimFormModuleId &&
        claimAdvancedFilters ? (
          <div className="d-flex gap-2 align-items-center pt-1">
            {/* in Medical Review the title will be different */}
            <label htmlFor="" className="">
              {pathname !== claimPaths.medicalReview
                ? staticLabels.isOwn
                : "Routed To Me"}
            </label>
            <div className="square-switch d-flex align-items-center">
              <input
                type="checkbox"
                id="square-switch2"
                switch="info"
                defaultChecked={claimAdvancedFilters.created_by}
                onChange={onClaimOwnerChange}
              />
              <label
                htmlFor="square-switch2"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        ) : null}

        {addOnButtons &&
          addOnButtons.length > 0 &&
          addOnButtons.map((addOn, index) => (
            <div className="text-sm-end" key={index + addOn.title}>
              {addOn.to ? (
                <Link
                  className={`btn ${addOn.bg} ${
                    addOn.disabled ? "disabled" : ""
                  }`}
                  aria-disabled={addOn.disabled}
                  tabIndex={addOn.disabled ? "-1" : "0"}
                  to={addOn.to ? addOn.to : "#"}
                  target={addOn.target ? addOn.target : "_self"}
                  onClick={addOn.onClick && addOn.onClick}
                >
                  <i className={`${addOn.icon} me-1`} />
                  {addOn.title}
                </Link>
              ) : (
                <button
                  className={`btn ${addOn.bg}`}
                  aria-disabled={addOn.disabled}
                  tabIndex={addOn.disabled ? "-1" : "0"}
                  onClick={addOn.onClick && addOn.onClick}
                  disabled={addOn.disabled ? true : false}
                >
                  <i className={`${addOn.icon} me-1`} />
                  {addOn.title}
                </button>
              )}
            </div>
          ))}

        {/* general export to excel */}
        {(subModuleId === productPCIId || asyncExport) && (
          <button
            type="button"
            aria-label="Load CSV Data"
            className="btn btn-secondary"
            onClick={() => setIsModal(true)}
          >
            {buttonsTitles.exportExcel}
          </button>
        )}

        {/* For Renewal Policies only */}
        {isBackPermission && backPageLink && (
          <div className="text-sm-end">
            <Link className="btn btn-secondary" to={backPageLink}>
              <i className="mdi mdi-keyboard-backspace me-1" />
              {buttonsTitles.back}
            </Link>
          </div>
        )}

        {/* Add Claim Reimbursement Icon Button */}
        {isAddClaimReimbursementPermission && addPageLink && (
          <div className="text-sm-end">
            <Link
              to={{
                pathname: addPageLink,
                state: { type_id: claimsTypes.reimbursement },
              }}
              className="btn btn-primary"
            >
              <i className="mdi mdi-plus me-1" />
              {isAddClaimReimbursementPermission?.name}
            </Link>
          </div>
        )}

        {/* Add Drop Down applied only in Invoice Module right now */}

        {isAddPermission && addDropDown && addDropDown}

        {/* Add Link */}
        {isAddPermission && addPageLink && (
          <div className="text-sm-end">
            <Link
              to={addPageLink}
              className="btn btn-success"
              target={isAddInNewTab ? "_blank" : "_self"}
            >
              <i className="mdi mdi-plus me-1" />
              {moduleId === claimBatchModuleId
                ? buttonsTitles.reimbursementBatch
                : buttonsTitles.addNew}
            </Link>
          </div>
        )}

        {/* Add Buttom */}
        {isAddPermission && !addPageLink && handleAdd && (
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn"
              onClick={handleAdd}
              aria-label="Add New"
            >
              <i className="mdi mdi-plus me-1" />
              {buttonsTitles.addNew}
            </Button>
          </div>
        )}

        {/*Reload Button*/}
        {includeReloadButton && (
          <div className="text-sm-end">
            <button className="btn btn-success" onClick={onReloadClick}>
              <i className="mdi mdi-reload me-1" />
              <span>{buttonsTitles.reload}</span>
            </button>
          </div>
        )}

        {/* For Claims only */}
        {isUnusedMarkPermission && handleAddMarkUnused && (
          <div className="text-sm-end">
            <button className="btn btn-info" onClick={handleAddMarkUnused}>
              <i className="mdi mdi-block-helper me-1" />
              Void Claim
            </button>
          </div>
        )}
        {/* For Claims Assignment only */}
        {isUserForceLogout && handleForceLogout && (
          <div className="text-sm-end">
            <button className="btn btn-danger" onClick={handleForceLogout}>
              <i className="bx bx-power-off me-1 text-white" />
              {isUserForceLogout?.name}
            </button>
          </div>
        )}

        {/* Batch Upload Button */}
        {isBatchUploadPermission && batchPageLink && (
          <div className="text-sm-end">
            <Link
              to={batchPageLink}
              target={isImportInSameTab ? "_self" : "_blank"}
              className="btn btn-info"
            >
              <i className="mdi mdi-cloud-upload me-1" />
              {isBatchUploadPermission?.name}
            </Link>
          </div>
        )}

        {/* Export Button */}
        {(isTableExportPermission || inCludeExportButton) && (
          <div className="text-sm-end">
            <ExportTable
              data={data}
              sheetName={sheetName}
              customCSVHeader={customCSVHeader}
              customCSVData={customCSVData}
              customPDFHeader={customPDFHeader}
              customPDFData={customPDFData}
              columns={columns}
              // ref={ref}
            />
          </div>
        )}

        {/* Eye Icon button  (show / hide)*/}
        {isTableViewPermission && (
          <div className="text-sm-end">
            <ShowHideColumns
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              fullColumns={fullColumns}
            />
          </div>
        )}

        {/* filter Icon Button */}
        {isTableFilterPermission && (
          <div className="text-sm-end">
            <Button type="button" color="primary" className="btn">
              <i className="mdi mdi-filter font-size-14" />
            </Button>
          </div>
        )}
      </div>

      {isModal && (
        <AysncExportCSVModal
          isOpen={isModal}
          sheetHeader={sheetHeader}
          setIsOpen={setIsModal}
          getSheetData={getSheetData}
          queryAddOn={queryAddOn}
          sheetName={sheetName}
          noPagingation={noPagingation}
          generateCustomData={generateCustomData}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(TableActionsButtons)
