/* Paths */
import { reportPaths } from "common/data/routePaths"
import { lazy } from "react"
/*
Components
*/

const DynamicReports = lazy(() => import("pages/Reports/DynamicReports"))
const BlackListedReports = lazy(() =>
  import("pages/Reports/BlackListedReports")
)
const DueToExpiryReport = lazy(() => import("pages/Reports/DueToExpiryReport"))
const AgentComissionReports = lazy(() =>
  import("pages/Reports/AgentComissionReports")
)
const NotRenewedReports = lazy(() => import("pages/Reports/NotRenewedReport"))
const AgentPoliciesReports = lazy(() =>
  import("pages/Reports/AgentPoliciesReports")
)
const VolumeRebateReport = lazy(() =>
  import("pages/Reports/VolumeRebateReport")
)
const FeesByBeneficiary = lazy(() => import("pages/Reports/FeesByBeneficiary"))
const FeesByClaims = lazy(() => import("pages/Reports/FeesByClaims"))
const PremiumFees = lazy(() => import("pages/Reports/PremiumFeesreports"))

// loss ratio
const LossRatio = lazy(() => import("pages/Reports/LossRatio"))
// Insurer Score board
const InsurerScoreboard = lazy(() => import("pages/Reports/InsurerScoreboard"))
//priority payer report
const PriorityPayerReport = lazy(() =>
  import("pages/Reports/PriorityPayerReport")
)
//Outstanding
const OutstandingInvoiceReports = lazy(() =>
  import("pages/Reports/OutstandingInvoiceReports")
)
const MunichreReport = lazy(() => import("pages/Reports/MunichreReport"))
const ServicePriceReport = lazy(() =>
  import("pages/Reports/ServicePriceReport")
)
const ExpertPerformance = lazy(() => import("pages/Reports/ExpertPerformance"))
const AccountingReport = lazy(() => import("pages/Reports/AccountingReport"))
const ProductionReports = lazy(() => import("pages/Reports/ProductionReports"))
const ClaimReport = lazy(() => import("pages/Reports/ClaimReport"))

const {
  dynamicReport,
  blackListedReport,
  dueToExpirtyReport,
  agentComissionReport,
  notRenewedReports,
  agentPoliciesReports,
  volumeRebateReport,
  feesByBeneficiaryReport,
  feesByClaimsReport,
  premiumFees,
  lossRatio,
  insurerScoreboard,
  priorityPayer,
  outstandingInvoiceReport,
  munichre,
  servicePrice,
  expertPerformance,
  accounting,
  production,
  claimReport,
} = reportPaths

export const ReportsRoutes = [
  // dynamic reports
  { path: dynamicReport, component: DynamicReports },
  // blacklisted reports
  { path: blackListedReport, component: BlackListedReports },
  // due to expiry reports
  { path: dueToExpirtyReport, component: DueToExpiryReport },
  // Agent Comission Reports reports
  { path: agentComissionReport, component: AgentComissionReports },
  // not renewed reports
  { path: notRenewedReports, component: NotRenewedReports },
  // agent policies reports
  { path: agentPoliciesReports, component: AgentPoliciesReports },
  // volume rebate report
  { path: volumeRebateReport, component: VolumeRebateReport },

  // fees by beneficiary report
  { path: feesByBeneficiaryReport, component: FeesByBeneficiary },
  // fees by claims report
  { path: feesByClaimsReport, component: FeesByClaims },
  // premium fees report
  { path: premiumFees, component: PremiumFees },
  // loss ratio report
  { path: lossRatio, component: LossRatio },
  // insurer score board
  { path: insurerScoreboard, component: InsurerScoreboard },
  // priority payer report
  { path: priorityPayer, component: PriorityPayerReport },

  {
    path: outstandingInvoiceReport,
    component: OutstandingInvoiceReports,
  },
  // Munich-re
  {
    path: munichre,
    component: MunichreReport,
  },
  {
    path: servicePrice,
    component: ServicePriceReport,
  },
  {
    path: expertPerformance,
    component: ExpertPerformance,
  },
  {
    path: accounting,
    component: AccountingReport,
  },
  {
    path: production,
    component: ProductionReports,
  },
  // Claim Report
  {
    path: claimReport,
    component: ClaimReport,
  },
]
