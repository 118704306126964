import { addConversation } from "helpers/General/conversation_helper"
import React, { Fragment, useState } from "react"

import { Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import { NotificationMessage, apiErrorrHandler } from "utils"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { FormControll, SearchSelect } from "components/TP/Common/Forms"
import { buttonsTitles, staticLabels } from "common/data"

import * as URLs from "helpers/url_helper"
import MultiSkeleton from "components/TP/Common/MultiSkeleton"
const ChatSidebar = ({
  selectedConversation,
  setSelectedConversation,
  refetchConversations,
  conversations,
  isLoading,
  resourceId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState("")
  const [conversationName, setConversationName] = useState("")
  const [accessType, setAccessType] = useState(null)

  const location = useLocation()
  const module_id = useSelector(state => state?.Module?.id)

  // add new conversation to db
  const onAddSubmit = async e => {
    e.preventDefault()
    const AddnewConversation = async () => {
      try {
        await addConversation({
          name: conversationName,
          module_id,
          resource_id: resourceId,
          access_type_id: accessType, //id of selected accesstype from dropdown
        })
        setError("")
        setIsOpen(false)
        refetchConversations()
      } catch (error) {
        const errorMessage = apiErrorrHandler(error)
        NotificationMessage("Error", errorMessage)
      }
    }
    setConversationName("")
    AddnewConversation()
  }

  return (
    <Fragment>
      <div>
        <Button
          className="btn btn-blue rounded-0"
          type="button"
          onClick={() => setIsOpen(true)}
          style={{
            padding: "8px 23px",
            whiteSpace: "nowrap",
          }}
        >
          Add Conversation
        </Button>
        <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
          <ModalHeader>Add new conversation</ModalHeader>
          <ModalBody>
            <Form onSubmit={onAddSubmit} className="d-flex flex-column">
              <SearchSelect
                name="access_type_id"
                label={staticLabels.chatAccessType}
                placeholder="Please Choose Access Type"
                // inputField={fieldFinder(inputs, 23)}
                value={accessType}
                customOnChange={selected => {
                  if (selected) {
                    setAccessType(selected?.id)
                    setConversationName(selected?.name)
                  } else {
                    setAccessType(null)
                  }
                }}
                onBlur={() => {}}
                url={URLs.ACCESSTYPES}
                mappingLabel="name"
                mappingValue="id"
                urlAddOns={{ is_featured: 1 }}
                invalid={false}
                wrapperClassNames="mb-3"
              />
              <FormControll
                type="text"
                label={staticLabels.conversationName}
                placeholder="Enter conversation name"
                value={conversationName}
                onChange={e => setConversationName(e.target.value)}
                className="ps-2 pr-4 w-100 border-1 mb-3"
              />
              <Button
                type="submit"
                className="mt-3 btn-blue align-self-end"
                disabled={!conversationName || !accessType}
              >
                {buttonsTitles.add}
              </Button>
            </Form>
          </ModalBody>
        </Modal>
        <ul
          className="list-unstyled chat-list bg-light bg-opacity-50"
          id="recent-list"
        >
          <PerfectScrollbar style={{ height: "560px" }}>
            {isLoading ? (
              <MultiSkeleton length={20} />
            ) : (
              conversations?.length > 0 &&
              conversations.map(conversation => (
                <li
                  key={conversation.id}
                  data-toggle="tooltip"
                  title={conversation.name}
                >
                  <div
                    className={`d-flex ${
                      selectedConversation === conversation.id ? "active" : ""
                    }`}
                    id="conversationName"
                    onClick={() => setSelectedConversation(conversation.id)}
                  >
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-13 mb-1">
                        {conversation.name.slice(0, 15)}
                        {conversation.name.length > 15 ? "..." : ""}
                      </h5>
                    </div>
                  </div>
                </li>
              ))
            )}
          </PerfectScrollbar>
        </ul>
        {/* </Col>
        </Row> */}
      </div>
    </Fragment>
  )
}

export default ChatSidebar
