import { lazy } from "react"

/* Paths */
import { dashboardPaths } from "common/data/routePaths"
/*
Components
*/
// claim dashboard
const ClaimDashboard = lazy(() => import("pages/Dashboard/ClaimDashboard"))
const FnolDashboard = lazy(() => import("pages/Dashboard/FnolDashboard"))
const LiveTrackingDashboard = lazy(() =>
  import("pages/Dashboard/LiveTrackingDashboard")
)
const PayerDashboard = lazy(() => import("pages/Dashboard/PayerDashboard"))
const ProductionDashboard = lazy(() =>
  import("pages/Dashboard/ProductionDashboard")
)
const StaticDashboard = lazy(() => import("pages/Dashboard/StaticDashboard"))

const {
  claimDashboard,
  productionDashboard,
  payerDashboard,
  fnolDashboard,
  liveTrackingDashboard,
} = dashboardPaths
export const DashboardsRoutes = [
  // Claim Dashboard
  { path: claimDashboard, component: ClaimDashboard },
  // live tracking Dashboard
  { path: liveTrackingDashboard, component: LiveTrackingDashboard },
  // Production Dashboard
  { path: productionDashboard, component: ProductionDashboard },
  //payer Dashboard
  { path: payerDashboard, component: PayerDashboard },
  //payer Dashboard
  { path: "/main-dashboard", component: StaticDashboard },
  //payer Dashboard
  { path: fnolDashboard, component: FnolDashboard },
]
