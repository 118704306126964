import { request } from "../api_helper"
import { PAYER } from "../url_helper"
/*
/Payer
*/

// add new Payer

// url
export const addPayer = (data) => request({ url: PAYER, method: "POST", data })

// get all Re-insurer with pagination

// url?page={pageNumber}&pagianate={Number of Rows per_page}

export const getAllPayer = ({
  page,
  per_page,
  sort_by,
  sort_type,
  search_keys,
  search_values,
  search_value,
  ...params
}) =>
  request({
    url: PAYER,
    method: "GET",
    params: {
      page: page ? page : 1,
      paginate: per_page ? per_page : 10,
      sort_by: sort_by ? sort_by : "id",
      sort_type: sort_type ? sort_type : "desc",
      search_keys,
      search_values,
      search_value,
      ...params,
    },
  })

// show Re-insurer
//url/{id}

export const showPayer = (payerId) =>
  request({ url: `${PAYER}/${payerId}`, method: "GET" })

// update Re-insurer
//url/{id}

export const updatePayer = (data) =>
  request({ url: `${PAYER}/${data.id}`, method: "PUT", data })

// delete Re-insurer
//url/{id}
export const deletePayer = (payerId) =>
  request({ url: `${PAYER}/${payerId}`, method: "DELETE" })

// activate Re-insurer
//url/{id}/activate
export const activatePayer = (payerId) =>
  request({ url: `${PAYER}/${payerId}/activate`, method: "POST" })

// deactivate Re-insurer
//url/{id}/deactivate
export const deactivatePayer = (payerId) =>
  request({ url: `${PAYER}/${payerId}/deactivate`, method: "POST" })

// Update Status (white list or Black Lits) Re-insurer
//url/{id}/statuses
export const updatePayerStatus = (data) =>
  request({
    url: `${PAYER}/${data.id}/statuses`,
    method: "POST",
    data,
  })

// Update Status (white list or Black Lits) Re-insurer
//url/{id}/statuses
export const updatePayerCard = (data) =>
  request({
    url: `${PAYER}/${data.payer_id}/beneficiary-card`,
    method: "PATCH",
    data,
  })
